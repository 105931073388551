import setTemporaryFocus from '../../utils/setTemporaryFocus';

class SkipLink {
    constructor(node) {
        this.node = node;
        this.node.addEventListener('click', (event) => this.#executeLink(event));
    }

    #executeLink(event) {
        event.preventDefault();
        const id = this.node.getAttribute('href').substring(1);
        const target = document.getElementById(id);
        if (target && target instanceof HTMLElement) {
            target.scrollIntoView();
            setTemporaryFocus(target);
        }
    }
}

export default SkipLink;
